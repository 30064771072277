<template>
  <div>
    <a-card title="用户管理">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input v-model="searchForm.search" placeholder="用户名, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.type" placeholder="类型" allowClear style="width: 100%" @change="search">
            <a-select-option key="agent" value="agent">代理商</a-select-option>
            <a-select-option key="staff" value="staff">员工</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px; float: right">
          <a-button type="primary" icon="plus" style="width: 100%" @click="createModalVisible = true">新增</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            @change="changeTable"
          >
            <template slot="is_active" slot-scope="value">
              <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
            </template>

            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @update="update" @destroy="destroy" @refresh="refresh" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <create-form-modal v-model="createModalVisible" @create="create" @cancel="createModalVisible = false" />
  </div>
</template>

<script>
import { codeToText } from "element-china-area-data";
import { insertItem, replaceItem, removeItem } from "@/utils/functions";
import { userList } from "@/apis/system";

export default {
  components: {
    CreateFormModal: () => import("./CreateFormModal"),
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "用户名",
          dataIndex: "username",
        },
        {
          title: "单位名称",
          dataIndex: "unit_name",
        },
        {
          title: "姓名",
          dataIndex: "name",
        },
        {
          title: "电话",
          dataIndex: "phone",
        },
        {
          title: "城市",
          dataIndex: "city_code",
          customRender: (value) => value.map((code) => codeToText[code]).join("/"),
          width: 120,
        },
        {
          title: "类型",
          dataIndex: "type_display",
        },
        {
          title: "等级",
          dataIndex: "level_display",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "默认跟进人",
          dataIndex: "is_default",
          customRender: (value) => (value ? "是" : "否"),
        },
        {
          title: "激活状态",
          dataIndex: "is_active",
          scopedSlots: { customRender: "is_active" },
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 240,
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],

      createModalVisible: false,
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      userList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    create(item) {
      this.dataItems = insertItem(this.dataItems, item);
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    refresh() {
      this.list();
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
