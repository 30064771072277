import request from "@/utils/request";

// User
export function userList(params) {
  return request({ url: `/users/`, method: "get", params });
}

export function userCreate(data) {
  return request({ url: `/users/`, method: "post", data });
}

export function userUpdate(data) {
  return request({ url: `/users/${data.id}/`, method: "put", data });
}

export function userDestroy(data) {
  return request({ url: `/users/${data.id}/`, method: "delete", data });
}

export function userResetPassword(data) {
  return request({ url: `/users/${data.id}/reset_password/`, method: "post", data });
}

export function userSetDefault(data) {
  return request({ url: `/users/${data.id}/set_default/`, method: "post", data });
}

// AuthorizationCertificate
export function authorizationCertificateUpload(data) {
  return request({
    url: "/authorization_certificates/",
    method: "post",
    data,
  });
}

// MakeToken
export function makeToken(data) {
  return request({ url: `/user/make_token/`, method: "post", data }, false);
}

// RefreshToken
export function refreshToken(data) {
  return request({ url: `/user/refresh_token/`, method: "post", data });
}

// LogoffToken
export function logoffToken(data) {
  return request({ url: `/user/logoff_token/`, method: "post", data });
}

// GetInfo
export function getInfo(params) {
  return request({ url: `/user/info/`, method: "get", params });
}

// SetPassword
export function setPassword(data) {
  return request({ url: `/user/set_password/`, method: "post", data });
}
